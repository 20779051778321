import { Hide1 } from "../../../../icons";


export default function Header({ close, booking_key, t }) {
    return (
        <div className="bg-white h-[65px] flex items-center justify-between px-4 lg:px-6 border-b border-borderColor max-lg:rounded-t-xl">
            <h2 className="textBody-m1 line-clamp-1">{t('appo_d_3', {ref:booking_key})}</h2>

            {/* btn */}
            <button onClick={close} className="outline-none hover:text-primary hover:scale-110">
                <Hide1 className="w-5 h-5" />
            </button>
        </div>
    )
}