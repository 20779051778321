import { calculateServicesTotalDuration, calculateServicesTotalPrice } from "../../../Helpers/utils";
import { Date, Services, Time } from "../../../icons";
import dayjs from "../../../lib/dayjs";


export default function ReservationDetails({ t, backToDateAndTimeSelection, data }) {

    const { services, date, time } = data;


    const getDateLabel = () => {
        const isToday = dayjs(date).isSame(dayjs(), 'day');
        const diff = dayjs(date).diff(dayjs(), 'day');

        if (isToday) return t('date.today');
        if (diff === 0) return t('date.tomorrow');

        // return t('date.afterDays', { count: diff });

        return diff === 1 ? t('date.afterDays', { count: diff }) : t('date.afterDays_plural', { count: diff });
    };


    const getTimeLabel = (selectedDateVal, selectedTimeVal) => {
        const selectedDate = dayjs(selectedDateVal);
        const selectedDateTime = dayjs(`${selectedDate.format('YYYY-MM-DD')} ${selectedTimeVal.replace(' PM', '').replace(' AM', '')}`);
        const now = dayjs();

        if (selectedDateTime.isBefore(now)) {
            return t('time.past'); // Translation key for 'Time has passed'
        }

        const diffInMinutes = selectedDateTime.diff(now, 'minute');
        const hours = Math.floor(diffInMinutes / 60);
        const minutes = diffInMinutes % 60;

        if (hours > 0 && minutes > 0) {
            return t('time.afterHoursAndMinutes', { hours, minutes }); // Translation key with interpolation
        } else if (hours > 0) {
            return t('time.afterHours', { hours }); // Translation key for hours
        } else {
            return t('time.afterMinutes', { minutes }); // Translation key for minutes
        }
    };


    const switchMinutesToHours = (num) => {
        const hours = Math.floor(num / 60);
        const minutes = num % 60;

        if (hours > 0 && minutes > 0) {
            return t('res_time.hoursAndMinutes', { hours, minutes });
        } else if (hours > 0) {
            return t('res_time.hours', { hours });
        } else {
            return t('res_time.minutes', { minutes });
        }
    };


    return (
        <div className="w-full max-w-5xl mx-auto pt-8 px-4 pb-20">
            <div className="flex items-center justify-between gap-2 mb-4 pb-3 border-b border-borderColor/60">
                <h4 className="lg:textBody-ml1 textBody-m1 text-fontColor">{t('booking_25')}</h4>
                <button
                    onClick={backToDateAndTimeSelection}
                    className="outline-none text-primary hover:underline lg:textBody-s2 textBody-xs2">
                    {t('booking_26')}
                </button>
            </div>
            <div className="w-full flex flex-col gap-3">
                <div className="flex items-center gap-2">
                    <Date className="w-5 h-5 text-fontColor2/80" />
                    <span className="lg:textBody-m2 textBody-s2 text-fontColor">
                        {dayjs(date).format('MMMM DD, YYYY')}
                        <span className="text-fontColor2/80 ltr:ml-2 rtl:mr-2">({getDateLabel()})</span>
                    </span>
                </div>

                <div className="flex items-center gap-2">
                    <Time className="w-5 h-5 text-fontColor2/80" />
                    <span className="lg:textBody-m2 textBody-s2 text-fontColor">
                        {time}
                        <span className="text-fontColor2/80 ltr:ml-2 rtl:mr-2">({getTimeLabel(dayjs(date).format('YYYY-MM-DD'), time)})</span>
                    </span>
                </div>

                <div className="flex items-start gap-2">
                    <Services className="w-5 h-5 text-fontColor2/80" />
                    <div className="lg:ltr:pl-3 ltr:pl-2 lg:ltr:border-l-2 ltr:border-l lg:rtl:pr-3 rtl:pr-2 lg:rtl:border-r-2 rtl:border-r lg:border-borderColor/60 border-borderColor flex flex-col gap-2.5 w-full">

                        {services?.map((service) => {
                            const { id, price, duration } = service;
                            return (
                                <div key={id} className="w-full flex items-center justify-between">
                                    <div className="flex flex-col gap-0.5">
                                        <span className="lg:textBody-m2 textBody-s2 text-fontColor">{t(`service_${id}`)}</span>
                                        <span className="lg:textBody-xs3 textBody-2xs3 text-fontColor2">{t('booking_20', { minutes: duration })}</span>
                                    </div>

                                    <span className={`lg:textBody-s2 textBody-xs2 text-fontColor2`}>
                                        {t('booking_21', { price })}
                                    </span>
                                </div>
                            )
                        })}

                        <div className="mt-1 pt-2 border-t border-borderColor/60 w-full flex items-center justify-between">
                            <span className={`lg:textBody-s2 textBody-xs2 text-primary`}>
                                {switchMinutesToHours(calculateServicesTotalDuration(services))}
                            </span>

                            <span className={`lg:textBody-s2 textBody-xs2 text-primary`}>
                                {t('booking_21', { price: calculateServicesTotalPrice(services) })}
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}