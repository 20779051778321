import { useEffect } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import Authentication from "../components/Authentication";
import Header from "../components/Pages/Home/Header";
import { useAuth } from "../hooks/useAuth";
import { isNull } from "../Helpers/utils";
import getEnv from "../Helpers/env";
import XLoader from "../components/Pagination/XLoader";


export default function ClientLayout() {

    const { isAuth, authUser } = useAuth()
    const { role } = authUser?.data ?? {};

    const navigate = useNavigate()


    // get appDashboardUrl url
    const appDashboardUrl = getEnv('appDashboardUrl') || "/";

    /**
     * redirect none auth users to the home page
     */
    useEffect(() => {
        const checkAuthStatus = () => {
            if (!isAuth) {
                navigate('/')
            } else {
                if (!isNull(role) && role !== "client") {
                    window.location.href = appDashboardUrl + 'appointments';
                }
            }
        };

        checkAuthStatus();
    }, [isAuth, role, navigate, appDashboardUrl])

    return (
        <>
            {authUser?.isLoading && <XLoader />}
            <main className="bg-dashBg min-h-dvh">
                <Authentication />
                <Header />
                <Outlet />
            </main>
        </>
    )
}