import ChangeLang from "../../../components/Language/ChangeLang";
import { Spinner } from "../../../icons";


export default function NavigationButton({ isDisabled, onClick, isLastStep, inProgress, totalPrice, t }) {

    const isDis = isDisabled || (isLastStep && inProgress);

    return (
        <div className="sticky bottom-0 bg-white border-t border-borderColor/60 w-full">
            <div className="w-full max-w-5xl mx-auto flex items-center lg:gap-2 gap-1 px-4 py-2">
                <button
                    disabled={isDis}
                    onClick={onClick}
                    className={`w-full outline-none whitespace-nowrap rounded-md py-3.5 px-6 transition-colors duration-200 select-none textBody-s2 lg:border-2 border flex justify-center
                        ${isDisabled
                            ? 'bg-gray-100 text-gray-500 lg:border-borderColor/80 border-borderColor cursor-not-allowed'
                            : 'text-white bg-primary hover:bg-primary/90 border-primary hover:border-primary/90'}
                    `}>
                    {isLastStep
                        ? !inProgress ? `${t('booking_28')} (${t('booking_21', { price: totalPrice })})` : <Spinner className="w-5 h-5 animate-spin" />
                        : t('booking_27')
                    }
                </button>

                <ChangeLang />
            </div>
        </div>
    )
}