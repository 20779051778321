import { n } from "../../../Helpers/utils";
import { AddNew1, Check } from "../../../icons";

export default function ServiceCard({ t, service, toggle, isSelected, totalSelectedServices }) {

    const { id, duration, price } = service;


    const selected = isSelected(id);

    
    const canSelectMore = n(totalSelectedServices) <= 3;


    const handleClick = () => {
        if (canSelectMore) {
            toggle(service)
        } else {
            if (selected) {
                toggle(service)
            }
        }
    }

    return (
        <div
            onClick={handleClick}
            className={`group rounded-md lg:border-2 border flex justify-between select-none
                ${selected
                    ? 'border-primary cursor-pointer'
                    : canSelectMore
                        ? 'lg:border-borderColor/60 border-borderColor hover:border-primary cursor-pointer'
                        : 'lg:border-borderColor/60 border-borderColor opacity-40 cursor-context-menu'
                }
        `}>
            <div className="flex flex-col gap-1 lg:pt-4 pt-3 lg:pb-3 pb-2 lg:ltr:pl-4 ltr:pl-3 lg:rtl:pr-4 rtl:pr-3">
                <h1 className="lg:textBody-s1 textBody-xs1 text-fontColor">{t(`service_${id}`)}</h1>
                <span className="lg:textBody-xs3 textBody-2xs3 text-fontColor2">{t('booking_20', { minutes: duration })}</span>
                <span className={`mt-1 lg:textBody-s2 textBody-xs2 ${selected ? 'text-primary' : canSelectMore ? 'text-fontColor2 group-hover:text-primary' : 'text-fontColor2'}`}>
                    {t('booking_21', { price })}
                </span>
            </div>

            <div className="flex items-center lg:py-4 py-3 lg:ltr:pr-3 ltr:pr-2 lg:rtl:pl-3 rtl:pl-2">
                <button className={`rounded-md outline-none p-1 ${selected ? 'bg-primary text-white' : canSelectMore ? 'bg-transparent hover:bg-primary/5 text-fontColor' : 'bg-transparent hover:bg-primary/5 text-fontColor cursor-context-menu'}`}>
                    {selected ? <Check className="lg:w-4 lg:h-4 w-3 h-3" /> : <AddNew1 className="lg:w-4 lg:h-4 w-3 h-3" />}
                </button>
            </div>

            {/* <span className="lg:py-4 py-3 lg:ltr:pr-3 ltr:pr-2 lg:rtl:pl-3 rtl:pl-2 lg:textBody-s2 textBody-xs2 text-fontColor2 group-hover:text-primary">
                {t('booking_21', { price })}
            </span> */}
        </div>
    )
}