import { Link } from "react-router-dom";
import TimeDifference from "./TimeDifference";


export default function Footer({ t, date, time, status, salonSlug }) {
    return (
        <div className="sticky bottom-0">
            <div className="bg-white h-[65px] flex items-center justify-between px-4 lg:px-6 border-t shadow-sm">
                <div className="w-full max-w-5xl mx-auto flex items-center justify-between lg:gap-2 gap-1">
                    {['active', 'confirmed'].includes(status) &&
                        <TimeDifference
                            selectedDateVal={date}
                            selectedTimeVal={time}
                            t={t}
                        />
                    }

                    <Link to={`/${salonSlug}`}>
                        <button className={`outline-none whitespace-nowrap select-none textBody-s2 text-fontColor2 hover:text-primary`}>
                            {t('appo_d_2')}
                        </button>
                    </Link>
                </div>

            </div>
        </div>
    )
}