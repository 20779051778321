import { useEffect } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import Authentication from "../components/Authentication";
import Header from "../components/Pages/Home/Header";
import { isNull } from "../Helpers/utils";
import { useAuth } from "../hooks/useAuth";
import getEnv from "../Helpers/env";
import XLoader from "../components/Pagination/XLoader";

export default function Layout() {

    const { authUser } = useAuth();
    const { role } = authUser?.data ?? {};

    const navigate = useNavigate()


    // get appDashboardUrl url
    const appDashboardUrl = getEnv('appDashboardUrl') || "/";

    /**
     * redirect none auth users to the home page
     */
    useEffect(() => {
        const redirectAuthUser = () => {
            if (!isNull(role)) {
                if (role === 'client') {
                    navigate('/client/appointments')
                } else {
                    window.location.href = appDashboardUrl + 'appointments';
                }
            }
        };

        redirectAuthUser();
    }, [role, navigate, appDashboardUrl])

    return (
        <>
            {authUser?.isLoading && <XLoader />}
            <main>
                <Authentication />
                <Header />
                <Outlet />
            </main>
        </>
    )
}