import SalonInformations from "./SalonInformations";
import { useParams } from 'react-router-dom';
import { GetSalonDataBySlug } from "../../lib/api";
import useHtmlAttributes from "../../hooks/useHtmlAttributes";
import { useTranslation } from "react-i18next";
import Authentication from "../../components/Authentication";
import DateAndTime from "./DateAndTime";
import { calculateServicesTotalPrice, isNull, n } from "../../Helpers/utils";
import SelectService from "./SelectService";
import { useState } from "react";
import ReservationSuccessMesasage from "./ReservationSuccessMesasage";
import NavigationButton from "./NavigationButton";
import ReservationDetails from "./ReservationDetails";
import { useBookingRequests } from "../../hooks/useBookingRequests";
import { useMemo } from "react";
import { useEffect } from "react";
import dayjs from "../../lib/dayjs";



export default function Salon() {

    const { beforeReservationRequest, reservationRequest, inProgress, isCompleted } = useBookingRequests();


    // extend htmlAttrebutes for translation and page direction
    const { mpt } = useHtmlAttributes();


    // call translator function
    const { t } = useTranslation()


    // get 'slug' parametter
    const { slug } = useParams();


    // get salon data
    const { data, loading } = GetSalonDataBySlug({ slug });
    const { data: salonData } = data ?? {};
    const { worktime: worktimeData, services: servicesData } = salonData ?? {};



    // state to store steps number
    const [stepNumber, setStepNumber] = useState(1)


    // state to store all appointment details
    const [selectedServices, setSelectedServices] = useState([])
    const [selectedDate, setSelectedDate] = useState(new Date());
    const [selectedTime, setSelectedTime] = useState('');



    // Start ##################################### Navigate To Next Available Date ##################################### //
    const availableDays = useMemo(() => {
        return worktimeData?.map(i => i.day) || [];
    }, [worktimeData]);

    useEffect(() => {
        if (availableDays?.length > 0 && dayjs(selectedDate).isSame(dayjs(), 'day')) {
            const today = dayjs();
            let nextDate = today;

            while (!availableDays.includes(nextDate.locale("en").format("dddd").toLowerCase())) {
                nextDate = nextDate.add(1, "day");
            }

            // setSelectedDate(nextDate.toDate());
            // Update only if the date actually changes
            if (!dayjs(selectedDate).isSame(nextDate, 'day')) {
                setSelectedDate(nextDate.toDate());
            }
        }
    }, [availableDays, selectedDate, setSelectedDate]);
    // End ##################################### Navigate To Next Available Date ##################################### //



    // render components by 'stepNumber'
    const renderContentByStepNumber = (num) => {
        switch (n(num)) {
            case (1):
                return (
                    <SelectService
                        t={t}
                        data={servicesData}
                        selectedServices={selectedServices}
                        setSelectedServices={setSelectedServices}
                    />
                );
            case (2):
                return (
                    <DateAndTime
                        t={t}
                        worktimeData={worktimeData}
                        slugParam={slug}
                        backToServicesSelection={() => setStepNumber(1)}
                        selectedDate={selectedDate}
                        setSelectedDate={setSelectedDate}
                        selectedTime={selectedTime}
                        setSelectedTime={setSelectedTime}
                        availableDays={availableDays}
                    />
                );
            case (3):
                return (
                    <ReservationDetails
                        t={t}
                        backToDateAndTimeSelection={() => setStepNumber(2)}
                        data={{
                            services: selectedServices,
                            date: selectedDate,
                            time: selectedTime,
                        }}
                    />
                );
            case (4):
                return (
                    <ReservationSuccessMesasage
                        t={t}
                    />
                );
            default:
                return null;
        }
    }


    // handle navigation button
    const handleNavigateBetweenSteps = () => {
        // after selecting services, navigate to select date and time
        if (stepNumber === 1 && !isNull(selectedServices)) {
            setStepNumber(2)
        }

        // after selecting date and time, show booking modal with appointment details
        if (stepNumber === 2 && !isNull(selectedDate) && !isNull(selectedTime)) {
            setStepNumber(3)
        }

        // after showing booking modal with appointment details, send booking request
        if (stepNumber === 3 && !isNull(selectedServices) && !isNull(selectedDate) && !isNull(selectedTime)) {
            // setStepNumber(4)
            if (beforeReservationRequest()) {
                reservationRequest({
                    setStepNumber,
                    appoDetails: {
                        services: selectedServices,
                        date: selectedDate,
                        time: selectedTime,
                        salon_id: salonData?.id,
                    },
                })
            }
        }
    }



    // Check if navigation button is disabled or not for each step
    const canNavigate =
        (stepNumber === 1 && !isNull(selectedServices)) ||
        (stepNumber === 2 && !isNull(selectedDate) && !isNull(selectedTime)) ||
        (stepNumber === 3 && !isNull(selectedServices) && !isNull(selectedDate) && !isNull(selectedTime));

    // Check if this is the latest step in the cooking process, to change the button title
    const isLastStep = stepNumber === 3 && canNavigate;



    return (
        <main>
            {mpt()}
            <Authentication />
            <div className="min-h-dvh">
                <SalonInformations
                    loading={loading}
                    salonData={salonData}
                />

                {(!loading && !isNull(salonData)) &&
                    renderContentByStepNumber(stepNumber)
                }
            </div>


            {(!loading && !isCompleted && !isNull(salonData)) &&
                <NavigationButton
                    isDisabled={!canNavigate}
                    onClick={handleNavigateBetweenSteps}
                    isLastStep={isLastStep}
                    inProgress={inProgress}
                    totalPrice={calculateServicesTotalPrice(selectedServices) || 0}
                    t={t}
                />}
        </main>
    )
}