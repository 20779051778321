import ChooseDate from "./ChooseDate"
import ChooseTime from "./ChooseTime"
import dayjs from "../../../lib/dayjs";
import { GetAvailableTimeBySalon } from "../../../lib/api";


export default function DateAndTime({
    t,
    slugParam,
    backToServicesSelection,
    selectedDate, setSelectedDate,
    selectedTime, setSelectedTime,
    availableDays,
}) {


    // get available time
    const params = {
        date: dayjs(selectedDate).format('YYYY-MM-DD'),
        salon: slugParam
    };

    const { data: availableTimeData, loading: availableTimeLoading } = GetAvailableTimeBySalon(params);
    const timesSlots = availableTimeData?.data ?? [];


    return (
        <div className="w-full max-w-5xl mx-auto pt-8 px-4 pb-20">
            <div className="flex items-center justify-between gap-2 mb-4 pb-3 border-b border-borderColor/60">
                <h4 className="lg:textBody-ml1 textBody-m1 text-fontColor">{t('booking_23')}</h4>
                <button
                    onClick={backToServicesSelection}
                    className="outline-none text-primary hover:underline lg:textBody-s2 textBody-xs2">
                    {t('booking_24')}
                </button>
            </div>
            <div className="w-full grid grid-cols-1 lg:gap-6 gap-4">
                <ChooseDate
                    selectedDate={selectedDate}
                    setSelectedDate={(d) => {
                        setSelectedDate(d)
                        setSelectedTime('')
                    }}
                    availableDays={availableDays}
                // t={t}
                />
                <ChooseTime
                    isLoading={availableTimeLoading}
                    availableTimes={timesSlots}
                    selectedTime={selectedTime}
                    setSelectedTime={setSelectedTime}
                    t={t}
                />
            </div>
        </div>
    )
}