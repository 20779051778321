
export default function DayCard({ day, fnc }) {

    const isToday = fnc?.isDateSame(day);
    const isPast = fnc?.isDatePast(day);
    const isSelected = fnc?.isDateSelected(day);
    const isHoliday = fnc?.isHolidays(day);


    const isDisabled = isPast || !isHoliday;


    const buttonColors = isDisabled
        ? 'cursor-not-allowed opacity-50 text-fontColor2 wp__disabled'
        : isSelected
            ? 'bg-primary border-primary text-white cursor-context-menu'
            : 'cursor-pointer lg:border-borderColor/60 border-borderColor hover:border-primary text-fontColor';


    return (
        <div className={`w-full flex flex-col items-center justify-center gap-2 select-none`}>
            <span className={`lg:textBody-s2 textBody-xs2 lg:hidden block line-clamp-1 cursor-context-menu ${isSelected ? 'text-primary' : 'text-fontColor'}`}>{day.format("ddd")}</span>
            <span className={`lg:textBody-s2 textBody-xs2 hidden lg:block line-clamp-1 cursor-context-menu ${isSelected ? 'text-primary' : 'text-fontColor'}`}>{day.format("dddd")}</span>

            <button className={`w-full flex flex-col items-center justify-center gap-0.5 lg:border-2 border rounded lg:py-3 md:py-2 py-2 px-1 relative ${buttonColors}`}
                onClick={() => {
                    if (!isDisabled) fnc?.onDateClicked(day)
                }}
            >
                <span className={`lg:textBody-s3 textBody-xs3`}>{day.format("D")}</span>

                {isToday && <span className="absolute lg:top-1 lg:right-1 top-0.5 right-0.5 lg:w-[8px] lg:h-[8px] w-[6px] h-[6px] bg-primary shadow-md rounded-full border border-white"></span>}
            </button>
        </div>
    )
}