import { Arrow } from "../../../icons";

export default function NavigationHeader({ fnc, currentMonth }) {
    return (
        <div className="flex items-center justify-between gap-2 mb-3">
            <button
                className="outline-none flex items-center justify-center p-2 hover:bg-gray-100 rounded-md text-fontColor lg:border-2 border lg:border-borderColor/60 border-borderColor"
                onClick={fnc?.handlePreviousWeek}>
                <Arrow className="lg:w-5 lg:h-5 w-4 h-4 rotate-180 rtl:rotate-0" />
            </button>

            <button
                className="outline-none flex items-center justify-center text-fontColor textBody-s3 hover:textBody-s2"
                onClick={fnc?.handleCurrentWeek}>
                <span>{currentMonth}</span>
            </button>

            <button
                className="outline-none flex items-center justify-center p-2 hover:bg-gray-100 rounded-md text-fontColor lg:border-2 border lg:border-borderColor/60 border-borderColor"
                onClick={fnc?.handleNextWeek}>
                <Arrow className="lg:w-5 lg:h-5 w-4 h-4 rotate-0 rtl:rotate-180" />
            </button>
        </div>
    )
}