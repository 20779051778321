import ModalLayout from "../../../../layouts/ModalLayout";
import dayjs from "../../../../lib/dayjs";
import { calculateServicesTotalDuration, calculateServicesTotalPrice, isNull, renderImage } from "../../../../Helpers/utils";
import Header from "./Header";
import Footer from "./Footer";


export default function AppointmentDetails({ close, appo, t }) {
    const { booking_date, booking_time, booking_status, salon, service_details, booking_key } = appo;

    const appTime = dayjs(`${dayjs(booking_date).format('YYYY-MM-DD')} ${booking_time}`);

    const totalDuration = service_details?.total_duration ?? 0;

    const switchMinutesToHours = (num) => {
        const hours = Math.floor(num / 60);
        const minutes = num % 60;

        if (hours > 0 && minutes > 0) {
            return t('res_time.hoursAndMinutes', { hours, minutes });
        } else if (hours > 0) {
            return t('res_time.hours', { hours });
        } else {
            return t('res_time.minutes', { minutes });
        }
    };


    const { name: salonName, slug: salonSlug, images: salonImage } = salon;


    return (
        <ModalLayout
            wallBackground="bg-black/30"
            // className={"fixed h-dvh w-full lg:w-[650px] right-0 top-0 z-[11013] animation-01 animation-04"}
            className={"fixed w-full lg:w-[650px] right-0 lg:top-0 z-[11013] max-lg:bottom-0 animation-01 animation-04 bg-white max-lg:rounded-t-xl"}
            close={close}
        >
            <Header
                close={close}
                booking_key={booking_key}
                t={t}
            />

            {/* <div className="shadow-lg bg-white w-full h-[calc(100dvh_-_130px)] overflow-y-auto"> */}
            <div className="bg-white w-full lg:h-[calc(100dvh_-_130px)] max-lg:h-full max-lg:max-h-[70dvh] overflow-y-auto">

                <div className="w-full border-b border-borderColor bg-cover bg-center" style={{ backgroundImage: `url("${renderImage(salonImage)}")` }}>
                    <div className="w-full h-full px-4 lg:px-6 py-6 backdrop-blur-lg bg-black/50">
                        <div className="w-fit mx-auto text-center">
                            <div
                                className="lg:w-24 lg:h-24 w-20 h-20 bg-primary/5 bg-cover bg-center rounded-xl mx-auto shadow"
                                style={{ backgroundImage: `url("${renderImage(salonImage)}")` }}
                            >
                            </div>
                            <div className="pt-3">
                                <h3 className="textHeader-xs text-white drop-shadow-md">{salonName}</h3>
                            </div>
                        </div>
                    </div>
                </div>


                <div className="bg-white px-4 lg:px-6 py-6 flex items-start justify-between gap-0.5 border-b border-borderColor/60">
                    <div className="flex flex-col gap-1">
                        <strong className="lg:textHeader-l textHeader-m text-fontColor">
                            {appTime.format('dddd, MMMM D, YYYY h:mm A')}
                        </strong>
                        <strong className="lg:textBody-m2 textBody-s2 text-fontColor">
                            {`${appTime.format('HH:mm A')} ${!isNull(totalDuration) ? `- ${appTime.add(totalDuration, 'minute').format('HH:mm A')}` : ``}`}
                        </strong>
                    </div>
                    <div className={`lg:px-4 px-3 py-1 rounded-full lg:textBody-xs2 textBody-2xs2 transition select-none shadow-sm ${renderStatusBadge(booking_status)}`}>
                        {t(renderAppoStatusName(booking_status))}
                    </div>
                </div>

                <div className="w-full flex flex-col gap-3 px-4 lg:px-6 py-6 mb-8">
                    {!isNull(service_details) &&
                        <div className="flex flex-col gap-3">
                            <span className="lg:textBody-l1 textBody-m1 text-fontColor">
                                {t('appo_d_1')}
                            </span>
                            <div className="px-1 flex flex-col gap-2.5 w-full">
                                {service_details?.services?.map((service) => {
                                    const { id, price, duration } = service;
                                    return (
                                        <div key={id} className="w-full flex items-center justify-between">
                                            <div className="flex flex-col gap-0.5">
                                                <span className="lg:textBody-s2 textBody-xs2 text-fontColor">{t(`service_${id}`)}</span>
                                                <span className="lg:textBody-xs3 textBody-2xs3 text-fontColor2">{t('booking_20', { minutes: duration })}</span>
                                            </div>

                                            <span className={`lg:textBody-s2 textBody-xs2 text-fontColor2`}>
                                                {t('booking_21', { price })}
                                            </span>
                                        </div>
                                    )
                                })}

                                <div className="mt-1 pt-2 border-t border-borderColor/60 w-full flex items-center justify-between">
                                    <span className={`lg:textBody-s2 textBody-xs2 text-primary`}>
                                        {switchMinutesToHours(calculateServicesTotalDuration(service_details?.services))}
                                    </span>
                                    <span className={`lg:textBody-s2 textBody-xs2 text-primary`}>
                                        {t('booking_21', { price: calculateServicesTotalPrice(service_details?.services) })}
                                    </span>
                                </div>
                            </div>
                        </div>
                    }
                </div>
            </div>

            <Footer
                t={t}
                date={booking_date}
                time={booking_time}
                status={booking_status}
                salonSlug={salonSlug}
            />
        </ModalLayout>
    )
}

const renderAppoStatusName = (stts) => {
    switch (stts) {
        case 'active':
            return "dash_appo_8";
        case 'confirmed':
            return "dash_appo_9";
        case 'canceled':
            return "dash_appo_11";
        case 'completed':
            return "dash_appo_10";
        default:
            return "";
    }
}

const renderStatusBadge = (status) => {
    switch (status) {
        case 'active':
            return "bg-[#52b035] text-white";
        case 'confirmed':
            return "bg-blue-500 text-white";
        case 'canceled':
            return "bg-red-500 text-white";
        case 'completed':
            return "bg-gray-500 text-white";
        default:
            return "bg-gray-500 text-white";
    }
};