import { useState } from "react";
import dayjs from "../../../lib/dayjs";
import { useEffect } from "react";
import NavigationHeader from "./NavigationHeader";
import DayCard from "./DayCard";
import Cookies from 'js-cookie'
import { languageCookieName } from "../../../variables";


export default function WeekPicker({ selectedDate, setSelectedDate, availableDays = [] }) {


    // get current language from cookies to update weekDays list automatically after changing lang
    const currentLang = Cookies.get(languageCookieName) || "en";


    const [startDate, setStartDate] = useState(dayjs(selectedDate).startOf("week"));

    
    useEffect(() => {
        setStartDate(dayjs(selectedDate).startOf("week"));
    },[selectedDate, currentLang])


    const generateWeekDays = (dd) => {
        return Array.from({ length: 7 }, (_, index) => dd?.add(index, "day"));
    };


    const weekDays = generateWeekDays(startDate);


    // Handlers for navigation
    const handleCurrentWeek = () => setStartDate(dayjs().startOf("week"));
    const handlePreviousWeek = () => setStartDate(startDate.subtract(7, "day"));
    const handleNextWeek = () => setStartDate(startDate.add(7, "day"));


    // handle date click
    const onDateClicked = (date) => {
        setSelectedDate(date.format("YYYY-MM-DD"))
    }

    // FNC to check 'date' is selected
    const isDateSelected = (d) => {
        return dayjs(selectedDate).isSame(d, 'day');
    }

    // FNC to check 'date' is same 'today'
    const isDateSame = (d) => {
        return dayjs().isSame(d, 'day');
    }

    // FNC to check 'date' is past
    const isDatePast = (d) => {
        return dayjs(d).isBefore(dayjs(), 'day');
    }

    // check (holidays)
    const isHolidays = (d) => {
        const selectedDayName_ = dayjs(d).locale("en").format("dddd").toLowerCase();

        return availableDays?.includes(selectedDayName_);
    };


    return (
        <div className="">
            {/* Navigation */}
            <NavigationHeader
                currentMonth={startDate?.format("MMMM YYYY")}
                fnc={{
                    handlePreviousWeek,
                    handleCurrentWeek,
                    handleNextWeek,
                }}
            />

            {/* Days */}
            <div className="w-full flex items-end justify-between lg:gap-2 gap-1">
                {weekDays?.map((day) => {
                    return <DayCard
                        key={day.format("YYYY-MM-DD")}
                        day={day}
                        fnc={{
                            onDateClicked,
                            isDateSelected,
                            isDateSame,
                            isDatePast,
                            isHolidays,
                        }}
                    />
                })}
            </div>
        </div>
    )
}