import { useAlerts } from "../context/AlertsContext";
import { useTranslation } from "react-i18next";
import { useAuth } from "./useAuth";
import { useSearchParams, useNavigate } from 'react-router-dom';
import { calculateServicesTotalDuration, calculateServicesTotalPrice, debounce, removeCurrencyKey } from "../Helpers/utils";
import { useState } from "react";
import { NewAppointment } from "../lib/api";
import dayjs from "../lib/dayjs";



export const useBookingRequests = () => {

    const navigate = useNavigate();


    const { t } = useTranslation()


    const { isAuth } = useAuth()


    const { addAlert } = useAlerts();



    //Start ########################################### Show Login Form Modal ########################################### //
    const [searchParams, setSearchParams] = useSearchParams();
    const showLoginForm = () => {
        searchParams.set('auth', 'register');
        searchParams.set('t', 'clients');
        setSearchParams(searchParams);
    }
    //End ########################################### Show Login Form Modal ########################################### //


    // Navigate to appointments page after 10 secounds after booking
    const navigateToAppointmentsPage = debounce(() => {
        navigate('/client/appointments')
    }, 10000);


    // send reservation request
    const [inProgress, setInProgress] = useState(false)
    const [isCompleted, setIsCompleted] = useState(false)
    const reservationRequest = async ({ setStepNumber, appoDetails }) => {

        const { services, date, time, salon_id } = appoDetails;

        const appoData = {
            date: dayjs(date).format('YYYY-MM-DD'),
            time: time,
            salon: salon_id,
            service_details: {
                services: removeCurrencyKey(services),
                total_price: calculateServicesTotalPrice(services),
                total_duration: calculateServicesTotalDuration(services),
                currency: services[0]?.currency ?? "",
            },
        };

        setInProgress(true)

        const { is_ok, message, type } = await NewAppointment(appoData);

        setInProgress(false)

        if (type === "validation") {
            // 
        } else {
            addAlert(message ?? (is_ok ? t('booking_8') : t('booking_9')), is_ok ? 'success' : 'error');
        }

        if (is_ok) {
            setIsCompleted(true)
            setStepNumber(4)
            navigateToAppointmentsPage()
        }
    }


    /**
     * Handles the reservation click event:
     * - Verifies user authentication.
     * - If the user is authenticated, it sends a booking request.
     * - If the user is not authenticated, it shows the registration form.
     */
    const beforeReservationRequest = () => {
        if (!isAuth) {
            showLoginForm()
            return false;
        }
        return true;
    }


    return {
        beforeReservationRequest,
        reservationRequest,
        inProgress,
        isCompleted,
    };
}