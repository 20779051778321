import React, { useState, useEffect } from 'react';
import dayjs from '../../../../lib/dayjs';


const TimeDifference = ({ selectedDateVal, selectedTimeVal, t }) => {
    const [now, setNow] = useState(dayjs());

    // Update `now` every minute
    useEffect(() => {
        const interval = setInterval(() => {
            setNow(dayjs());
        }, 60000); // 60000 ms = 1 minute

        return () => clearInterval(interval); // Cleanup on component unmount
    }, []);

    const getDiff = () => {
        const selectedDate = dayjs(selectedDateVal);
        const selectedDateTime = dayjs(`${selectedDate.format('YYYY-MM-DD')} ${selectedTimeVal.replace(' PM', '').replace(' AM', '')}`);

        if (selectedDateTime.isAfter(now.subtract(30, 'minute')) && selectedDateTime.isBefore(now)) {
            return <span className="lg:textBody-xs3 textBody-2xs3 text-green-500 drop-shadow">{t('time.current')}</span>;
        }

        if (selectedDateTime.isBefore(now)) {
            return <span className="lg:textBody-xs3 textBody-2xs3 text-red-500 drop-shadow">{t('time.expired')}</span>;
        }

        const diffInMinutes = selectedDateTime.diff(now, 'minute');
        const hours = Math.floor(diffInMinutes / 60);
        const minutes = diffInMinutes % 60;

        if (hours > 0 && minutes > 0) {
            return (
                <span className="lg:textBody-xs3 textBody-2xs3 text-green-500 drop-shadow">
                    {t('time.afterHoursAndMinutes', { hours, minutes })}
                </span>
            );
        } else if (hours > 0) {
            return (
                <span className="lg:textBody-xs3 textBody-2xs3 text-green-500 drop-shadow">
                    {t('time.afterHours', { hours })}
                </span>
            );
        } else {
            return (
                <span className="lg:textBody-xs3 textBody-2xs3 text-green-500 drop-shadow">
                    {t('time.afterMinutes', { minutes })}
                </span>
            );
        }
    };

    return <>{getDiff()}</>;
};

export default TimeDifference;