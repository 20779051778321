import WeekPicker from "../../../../components/Shared/WeekPicker";


export default function ChooseDate({
    selectedDate,
    setSelectedDate,
    availableDays,
    // t,
}) {

    return (
        <div className="">
            <WeekPicker
                selectedDate={selectedDate}
                setSelectedDate={setSelectedDate}
                availableDays={availableDays}
            />
        </div>
    )
}