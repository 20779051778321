import { useEffect } from "react";
import { Spinner } from "../../icons";


export default function XLoader() {

    // hide scrolling
    useEffect(() => {
        const bodyElement = document?.body;
        const hasScrollbar = window?.innerWidth > document?.documentElement.clientWidth;

        bodyElement.style.overflow = "hidden";
        if (hasScrollbar) {
            bodyElement.style.paddingRight = "17px";
        }

        return () => {
            bodyElement.style.overflow = "";
            if (hasScrollbar) {
                bodyElement.style.paddingRight = "";
            }
        };
    },[])

    return (
        <div className="fixed top-0 left-0 z-[2147483647] w-full min-h-dvh bg-primary text-white p-6 flex items-center justify-center">
            <Spinner className="w-10 h-10 animate-spin" />
        </div>
    )
}